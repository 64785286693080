/* @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const getPaddingForSize = (size: string) => {
  switch (size) {
    case 'large':
      return '20px 50px';
    case 'medium':
      return '10px 30px';
    default:
      return '5px 20px';
  }
};
const renderCSS = ({ size }: { size: ButtonProps['size'] }) => css`
  display: block;
  border-radius: 8px;
  background-color: #44e08d;
  padding: ${getPaddingForSize(size || 'default')};
  border: 0;
  color: #fff;
  cursor: pointer;
`;

interface ButtonProps {
  onClick?: () => void;
  htmlType?: 'submit' | 'button';
  children?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
}

const Button = (props: ButtonProps) => {
  const { onClick, children, size = 'medium', style } = props;
  return (
    <button
      css={renderCSS({ size })}
      style={style}
      onClick={onClick ? onClick : undefined}
    >
      {children}
    </button>
  );
};
export default Button;
