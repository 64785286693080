import React from 'react';
import { css } from '@emotion/core';

import Layout from '../components/layout';
import SEO from '../components/seo';

import website from '../images/website.png';
import call_rep from '../images/step1_rep.jpg';
import Button from '../components/Button';

const contentWrapCSS = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;

  padding: 50px;

  h1 {
    margin-top: 60px;
    font-size: 62px;
  }

  h2 {
    margin-bottom: 20px;
    font-weight: 500;
    color: #517bfe;
    font-size: 24px;
  }

  a {
    text-decoration: none;
  }
`;

const imageChartCSS = css`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 20px;

  @media only screen and (max-width: 480px) {
    display: block;

    .column {
      margin: auto;
      margin-bottom: 30px;
    }

    h4 {
      margin-bottom: 35px;
    }
  }

  .column {
    padding: 20px;
    width: 275px;

    img {
      width: 125px;
      margin-bottom: 15px;
    }

    h4 {
      color: #517bfe;
      margin-bottom: 5px;
    }
  }
`;

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank you" />
    <div css={contentWrapCSS}>
      {/* <h1>Thank you!</h1>
      <p>
        One of our student loan specialists will get in touch with you to review
        your situation.
      </p> */}
      <h2>IMPORTANT STEP - Is student debt affecting your credit?</h2>
      <p style={{ marginBottom: 30 }}>
        Thank you for your inquiry. A student debt specialist will be reaching
        out to you soon for your FREE consultation. In the meantime, we advise a
        quick credit check to see how your current loan payments may be
        impacting your credit score. Click below to access your FREE score -
        courtesy of Credit Sesame now!
      </p>
      <p style={{ textAlign: 'center', marginBottom: 100 }}>
        <Button
          size="large"
          style={{ margin: '0 auto', marginBottom: 10 }}
          onClick={() =>
            window.open(
              'https://www.bhmediatrack.com/9FFC47/K4B46B/?sub1=&sub2=&sub3='
            )
          }
        >
          Access My Free Credit Score
        </Button>
        <small>
          Note: Checking your credit with Credit Sesame, will NOT hurt your
          scores.
        </small>
      </p>
    </div>
  </Layout>
);

export default ThankYouPage;
